import React, { useEffect, useRef, useState, useCallback } from 'react';
import classnames from 'classnames';
import { ReactComponent as BurgerIcon } from '../../../images/Burger.svg';
import { ReactComponent as CloseIcon } from '../../../images/Close.svg';
import { initialHeaderHeight } from '..';
import { NavLink } from './NavLink';

import styles from './index.module.scss';

export const Navigation: React.FC<{
    callback: (offset: number) => void;
}> = ({ callback }) => {
    const navRef = useRef<HTMLDivElement>(null);
    const [menuOpen, setMenuState] = useState(false);

    const toggleMenu = useCallback(() => {
        setMenuState(!menuOpen);
    }, [menuOpen]);

    useEffect(() => {
        if (navRef.current) {
            callback(initialHeaderHeight - navRef.current.offsetHeight);
        }
    }, [callback]);

    useEffect(() => {
        document.addEventListener('mousedown', handleClick);

        return () => document.removeEventListener('mousedown', handleClick);
    }, []);

    const handleClick = (event: MouseEvent) => {
        if (navRef.current && event.target && !navRef.current.contains(event.target as Node)) {
            setMenuState(false);
        }
    };

    const onNavigate = () => {
        setMenuState(false);
    };

    const menuItems = (
        <>
            <NavLink className={styles.navLink} onClick={onNavigate} activeClassName={styles.active} to="/talks">
                Alle Talks
            </NavLink>
            <NavLink className={styles.navLink} onClick={onNavigate} activeClassName={styles.active} to="/about">
                Über die Talks
            </NavLink>
            <NavLink className={styles.navLink} onClick={onNavigate} activeClassName={styles.active} to="/registration">
                Anmeldung
            </NavLink>
            <NavLink className={styles.navLink} onClick={onNavigate} activeClassName={styles.active} to="/imprint">
                Impressum
            </NavLink>
        </>
    );

    const overlayClasses = classnames(styles.burgerMenuOverlay, {
        [styles.open]: menuOpen,
    });

    return (
        <div ref={navRef} className={styles.navigation}>
            <div className={styles.largeNavigation}>{menuItems}</div>
            <div className={styles.burgerMenu}>
                <button className={styles.burgerToggle} onClick={toggleMenu}>
                    <BurgerIcon className={classnames({ [styles.hideIcon]: menuOpen })} aria-hidden={true} />
                    <CloseIcon className={classnames({ [styles.hideIcon]: !menuOpen })} aria-hidden={true} />
                    <span className="sr-only">{menuOpen ? 'close menu' : 'open menu'}</span>
                </button>
                <div className={overlayClasses}>{menuItems}</div>
            </div>
        </div>
    );
};

import React, { useState, useEffect } from 'react';
import { MultilineTitle, TitleColor, TitlePosition } from '../../components/MultilineTitle';

import styles from './index.module.scss';
import { Heroimage } from '../../components/Heroimage';
import RegistrationImage from '../../images/heroimages/Heroimage_Anmeldung.jpg';
import { ITalk } from '../../components/TalkInput/model';
import { Newsletter } from '../../components/Newsletter';
import { NextTalk } from '../../components/NextTalk';
import { getNextTalk } from '../../utils/api/talk';
import useScrollUp from '../../utils/useScrollUp';

export const Registration: React.FC = () => {
    const [talk, setTalk] = useState<ITalk>();
    useScrollUp();

    useEffect(() => {
        getNextTalk()
            .then((res) => {
                const nextTalk: ITalk = res.talks !== null && res.talks !== undefined ? res.talks[0] : undefined;
                if (nextTalk) {
                    nextTalk.talkDateTime = new Date(nextTalk.talkDateTime);
                    setTalk(nextTalk);
                }
            })
            .catch((err) => console.error(err));
    }, []);

    const registrationHeroimage = <Heroimage image={RegistrationImage} title="Melde dich kostenlos an &" subTitle="komm vorbei!"></Heroimage>;

    if (talk !== undefined && talk !== null) {
        return (
            <div>
                {registrationHeroimage}
                <NextTalk talk={talk} />
                <p className={styles.description} dangerouslySetInnerHTML={{ __html: talk.description }} />
            </div>
        );
    } else {
        return (
            <div>
                {registrationHeroimage}
                <MultilineTitle color={TitleColor.LIME_GREEN} position={TitlePosition.LEFT} className={styles.unannounced}>
                    Der nächste Talk steht noch nicht fest.
                </MultilineTitle>
                <Newsletter />
            </div>
        );
    }
};

import React from 'react';

import styles from './index.module.scss';
import { Social } from '../Social';
import { Link } from 'react-router-dom';
import classnames from 'classnames';

export const Footer: React.FC = () => {
    return (
        <footer className={styles.footer}>
            <Social />
            <div className={styles.footerLinks}>
                <div className={classnames(styles.third, styles.left)}>
                    <Link to="/talks" className={styles.link}>
                        ALLE TALKS
                    </Link>
                    <Link to="/registration" className={styles.link}>
                        ANMELDUNG
                    </Link>
                    <Link to="/imprint" className={styles.link}>
                        IMPRESSUM, KONTAKT, DATENSCHUTZ
                    </Link>
                </div>
                <p className={classnames(styles.third, styles.center)}>© cosee {new Date().getFullYear()}</p>
                <p className={classnames(styles.third, styles.right)}>
                    Apple, das Apple-Logo und deren Geräte sind Warenzeichen von Apple Inc., die in den USA und in anderen Ländern registriert sind.
                </p>
            </div>
        </footer>
    );
};

import React from 'react';

import styles from './index.module.scss';
import { Tag } from '../Tag';
import { ITalk } from '../TalkInput/model';
import { LinkAsButton } from '../LinkAsButton';
import { ButtonColors } from '../Button';

export const NextTalk: React.FC<{ talk: ITalk }> = ({ talk }) => {
    const talkDate = new Date(talk.talkDateTime);

    return (
        <div className={styles.wrapper}>
            <div className={styles.talkInformation}>
                <span className={styles.talkDate}>
                    NEXT:{' '}
                    {talkDate
                        .toLocaleDateString('de-DE', {
                            year: 'numeric',
                            month: 'long',
                            day: 'numeric',
                        })
                        .toLocaleUpperCase()}
                </span>
                <h1 className={styles.talkTitle}>{talk.title}</h1>
                {talk.tags && (
                    <div>
                        {talk.tags.map((tag, index) => (
                            <Tag className={styles.talksTags} key={'tag' + index}>
                                {tag}
                            </Tag>
                        ))}
                    </div>
                )}
                {talk.eventUrl && (
                    <LinkAsButton className={styles.button} color={ButtonColors.GREEN} to={talk.eventUrl} external>
                        KOSTENLOS ANMELDEN
                    </LinkAsButton>
                )}
            </div>
            <img className={styles.talkImage} src={'/' + talk.marketingImage} alt="" />
        </div>
    );
};

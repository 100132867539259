import React from 'react';
import { Tag } from '../Tag';

import styles from './index.module.scss';
import { ITalkPreview } from '../../pages/Talks/Overview';
import { Link } from 'react-router-dom';

interface ITalkCardProps {
    talk: ITalkPreview;
}

export const TalkCard: React.FC<ITalkCardProps> = ({ talk }) => {
    const link = `/talk/${talk.id}`;
    const talkDate = new Date(talk.talkDateTime);

    const dateTitle = <span>{talkDate.toLocaleDateString('de-DE', { year: 'numeric', month: 'long' }).toLocaleUpperCase()}</span>;

    return (
        <Link to={link} className={styles.card}>
            {dateTitle}
            {talk.previewImage && (
                <img className={styles.cardImage} src={'/' + talk.previewImage} alt={talk.speaker ? 'Speaker: ' + talk.speaker.join(', ') : ''} />
            )}
            <h2>{talk.title}</h2>
            {talk.subtitle ? <h3>{talk.subtitle}</h3> : null}
            {talk.tags && (
                <div>
                    {talk.tags.map((tag, index) => (
                        <Tag key={'tag' + index}>{tag}</Tag>
                    ))}
                </div>
            )}
        </Link>
    );
};

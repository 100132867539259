import React, { useState, useEffect } from 'react';

import styles from './index.module.scss';
import { Logo } from './Logo';
import { Navigation } from './Navigation';

export const initialHeaderHeight = 16 * 8;

export const Header: React.FC = () => {
    const [headerHeight, setHeaderHeight] = useState<number>(initialHeaderHeight);
    const [maxShrinkSize, setMaxShrinkSize] = useState<number>(0);
    const [currentScrollPosition, setCurrentScrollPosition] = useState<number>(0);

    function handleScroll() {
        let scrollPosition = window.scrollY;
        if (scrollPosition >= maxShrinkSize) {
            if (currentScrollPosition === maxShrinkSize) {
                return;
            }
            scrollPosition = maxShrinkSize;
        }
        setCurrentScrollPosition(scrollPosition);
        setHeaderHeight(initialHeaderHeight - scrollPosition);
    }

    useEffect(() => {
        window.addEventListener('scroll', handleScroll);

        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    });

    return (
        <nav>
            <div className={styles.header} style={{ height: headerHeight + 'px' }}>
                <div className={styles.container}>
                    <Logo />
                    <Navigation callback={setMaxShrinkSize}>Navigation</Navigation>
                </div>
            </div>
            <div className={styles.spacer} style={{ height: initialHeaderHeight + 'px' }} />
        </nav>
    );
};
